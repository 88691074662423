import { Component, Vue } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";
import { Card } from "@/types/card";
import { Bank } from "@/types/bank";

@Component({
  components: {
    CardActions: () =>
      import("../../../../components/CardActions/CardActions.vue"),
  },
})
export default class CardActionsMenu extends Vue {
  @Prop({ type: Object, required: true }) private readonly card!: Card & {
    bank?: Bank;
  };

  private showedMenu = false;

  private onCloseCard(id: number) {
    this.toggleShowMenu(false);
    this.$emit("close:card", id);
  }

  private onFreezeCard(id: number) {
    this.toggleShowMenu(false);
    this.$emit("freeze:card", id);
  }

  private onUnfreezeCard(id: number) {
    this.toggleShowMenu(false);
    this.$emit("unfreeze:card", id);
  }

  private toggleShowMenu(showed = !this.showedMenu) {
    this.showedMenu = showed;
  }
}
